export const LOAD_STORIES_LIST = "LOAD_STORIES_LIST";
export const LOAD_STORIES_LIST_SUCCESS = "LOAD_STORIES_LIST_SUCCESS";

export const DELETE_STORY = "DELETE_STORY";
export const DELETE_STORY_SUCCESS = "DELETE_STORY_SUCCESS";

export const DOWNLOAD_STORY_FILE = "DOWNLOAD_STORY_FILE";
export const DOWNLOAD_STORY_FILE_FROM_URL = "DOWNLOAD_STORY_FILE_FROM_URL";

export const API_FAILED = "STORIES_LIST_API_FAILED";

export const DEFAULT_PICTURE_B64 = "PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA0MCA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSI1NiIgcng9IjQiIGZpbGw9IiMxNDE0MTQiLz4NCjxyZWN0IHg9IjExLjY1NjIiIHk9IjE3LjgyODEiIHdpZHRoPSIxNi42NDA2IiBoZWlnaHQ9IjIwLjI5NjkiIHJ4PSIyLjYyNSIgc3Ryb2tlPSIjNzI3MjcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTIzLjc1IDI1Ljc1QzI0LjU3ODQgMjUuNzUgMjUuMjUgMjUuMDc4NCAyNS4yNSAyNC4yNUMyNS4yNSAyMy40MjE2IDI0LjU3ODQgMjIuNzUgMjMuNzUgMjIuNzVDMjIuOTIxNiAyMi43NSAyMi4yNSAyMy40MjE2IDIyLjI1IDI0LjI1QzIyLjI1IDI1LjA3ODQgMjIuOTIxNiAyNS43NSAyMy43NSAyNS43NVoiIHN0cm9rZT0iIzcyNzI3MiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPg0KPHBhdGggZD0iTTIxLjkxNDcgMzEuNzI4N0wxOC4yOTgzIDI4LjEzNzVDMTguMDY4MiAyNy45MDg2IDE3Ljc1ODkgMjcuNzc1OCAxNy40MzM3IDI3Ljc2NjJDMTcuMTA4NCAyNy43NTY2IDE2Ljc5MTggMjcuODcxIDE2LjU0ODQgMjguMDg1OUwxMS43MDMxIDMyLjM3Mk0xOC43MjM2IDM1LjU0NjlMMjMuNjQzNCAzMC42NTJDMjMuODY4NCAzMC40Mjc3IDI0LjE2OTYgMzAuMjk1MiAyNC40ODc4IDMwLjI4MDVDMjQuODA1OSAzMC4yNjU4IDI1LjExODEgMzAuMzcgMjUuMzYzMSAzMC41NzI2TDI4LjI5NjkgMzMuMDA3IiBzdHJva2U9IiM3MjcyNzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0K"