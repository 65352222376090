import React, { useEffect, useState, useRef } from 'react';
import { FormGroup, Alert, Form, Input, Button, FormFeedback, InputGroup } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import withRouter from "../../../components/withRouter";
import { useFormik } from 'formik';
import * as Yup from 'yup';

// i18n
import { useTranslation } from 'react-i18next';

// Actions
import { loginUser, apiError } from '../../../redux/actions';

// Selectors
import { selectAuth } from '../../../redux/auth/selectors';

/**
 * Login component
 * @param {*} props 
 */
const LoginForm = ({ error, loading, ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const prevLoadingRef = useRef(loading); // Necessary because formik updates before the reducer switches to 'pending'
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    // validation
    const formik = useFormik({
        initialValues: {
            user: '',
            password: ''
        },
        validationSchema: Yup.object({
            user: Yup.string().required('Please Enter Your Username Or Email'),
            password: Yup.string().required('Please Enter Your Password')
        }),
        onSubmit: values => {
            dispatch(apiError(""));
            dispatch(loginUser(values.user, values.password, props.router.navigate));
        },
    });

    useEffect(() => {
        if (formik.isSubmitting && prevLoadingRef.current && !loading) {
            formik.setSubmitting(false);
        };
        prevLoadingRef.current = loading;
    }, [loading, formik]);

    if (localStorage.getItem("authUser")) {
        return <Navigate to="/dashboard" />;
    }

    const navigate = (e, to) => {
        if (props.navigate) {
            e.preventDefault();
            props.navigate(to)
        }
    }

    return (
        <div className="justify-content-center">
            <div className="text-center px-4 px-lg-5 mb-4">
                <p className="text-reset ff-special fw-normal h1 mb-3">{t('Login')}</p>
                <Link to="/register" onClick={(e) => { navigate(e, 'register') }} className="font-weight-medium text-decoration-underline mb-4 d-inline-block"> {t("Don't you have an account?")} </Link>
                {formik.submitCount > 0 && error && !formik.isSubmitting && (
                    <Alert color="danger">
                        <div>{(error?.detail && Array.isArray(error.detail) ? error.detail[0].msg : error.detail) || error}</div>
                    </Alert>
                )}
                {
                    formik.submitCount > 0 && error?.detail && error.detail === 'User is not active' && <Link to="/validate-registration" onClick={(e) => { navigate(e, 'validate-registration') }} className="font-weight-medium text-decoration-underline mb-4 d-inline-block"> {t('Activate your user by clicking here')} </Link>
                }
                <div>

                    <Form onSubmit={formik.handleSubmit}>

                        <FormGroup className="mb-3">
                            <InputGroup className="mb-3 bg-soft-light rounded-3">
                                <Input
                                    type="text"
                                    id="user"
                                    name="user"
                                    className="form-control form-control-lg border-light bg-soft-light"
                                    placeholder="Enter Username Or Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.user}
                                    invalid={formik.touched.user && formik.errors.user ? true : false}
                                />
                                {formik.touched.user && formik.errors.user ? (
                                    <FormFeedback type="invalid">{formik.errors.user}</FormFeedback>
                                ) : null}
                            </InputGroup>
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <InputGroup className="mb-3 bg-soft-light rounded-3">
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    className="form-control form-control-lg border-light bg-soft-light"
                                    placeholder="Enter Password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    invalid={formik.touched.password && formik.errors.password ? true : false}
                                />
                                <Button type="button" color="link py-0 px-2 text-body btn-eye" onClick={handleTogglePassword}><i className="ri-eye-line font-size-24 fw-normal"></i></Button>
                                {formik.touched.password && formik.errors.password ? (
                                    <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                                ) : null}

                            </InputGroup>
                        </FormGroup>

                        <div className="d-grid">
                            <Button color="secondary" size="lg" block className=" waves-effect waves-light" type="submit" disabled={formik.isSubmitting}>{t('Login')}</Button>
                        </div>

                        <div className="text-center mt-2">
                            <Link to="/forget-password" onClick={(e) => { navigate(e, 'forget-password') }} className="font-size-13">{t('Forgot password')}?</Link>
                        </div>

                        {formik.isSubmitting && <div className="d-flex justify-content-center mt-4"><div className="loader"></div></div>}
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { loading, error } = selectAuth(state)

    return { loading, error };
};

export default withRouter(connect(mapStateToProps, { loginUser, apiError })(LoginForm));