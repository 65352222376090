export const WEBSOCKET_CONNECT = "WEBSOCKET_CONNECT";
export const WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED";
export const WEBSOCKET_MESSAGE = "WEBSOCKET_MESSAGE";
export const WEBSOCKET_DISCONNECT = "WEBSOCKET_DISCONNECT";
export const SEND_MESSAGE = "SEND_MESSAGE";

export const websocket_commands_messages = {
    AI_DONE_WORKING: 'ai_done_working',
    AI_IS_WORKING: 'ai_is_working',
    ASK_FOR_REGISTRATION: 'ask_for_registration',
    CONVERSATION_RECOVERY: 'conversation_recovery',
    DONE: 'done',
    LINK_USER_WITH_CONVERSATIONS: 'link_user_with_conversations',
    MESSAGE_FOR_HUMAN: 'message_for_human',
    NEW_TALE: 'new_tale',
    PROGRESS_UPDATE: 'progress_update',
    SPIN_OFF: 'spin_off',
    STATUS_UPDATE: 'status_update',
    UPDATE_PROFILE: 'update_profile',
    PROFILE_UPDATED: 'profile_updated',
    USER_MESSAGE: 'user_message', 
}