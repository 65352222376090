export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const VALIDATE_USER_REGISTER = "VALIDATE_USER_REGISTER";
export const VALIDATE_USER_REGISTER_SUCCESS = "VALIDATE_USER_REGISTER_SUCCESS";
export const RESEND_VERIFICATION_CODE = "RESEND_VERIFICATION_CODE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const CREATE_USER_TRY_MODE= "CREATE_USER_TRY_MODE"
export const CREATE_USER_TRY_MODE_SUCCESS = "CREATE_USER_TRY_MODE_SUCCESS"

export const API_FAILED = "AUTH_API_FAILED";