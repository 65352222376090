import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

//Import formik validation
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormGroup, Alert, Form, Input, Button, FormFeedback, Label, InputGroup } from 'reactstrap';

//Import actions and helpers
import { forgetPassword, apiError } from '../../../redux/actions';

//i18n
import { useTranslation } from 'react-i18next';
import { selectAuth } from '../../../redux/auth/selectors';

/**
 * Forget Password component
 * @param {*} props 
 */
const SendCodeForm = (props) => {
    const { loading, error } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prevLoadingRef = useRef(loading); // Necessary because formik updates before the reducer switches to 'pending'
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    // validation
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Enter a valid email address').required('Required')
        }),
        onSubmit: values => {
            dispatch(forgetPassword(values.email));
        },
    });

    useEffect(() => {
        if (formik.isSubmitting && prevLoadingRef.current && !loading) {
            formik.setSubmitting(false);
            setSuccess(!error);
        };
        prevLoadingRef.current = loading;
    }, [loading, error, formik]);

    const navigate = useCallback(({ e, to }) => {
        if (props.navigate) {
            e?.preventDefault();
            props.navigate(to);
        }
    }, [props]);

    useEffect(() => {
        if (success) {
            navigate({ to: 'change-password' });
        }
    }, [success, navigate]);

    if (localStorage.getItem("authUser")) {
        return <Navigate to="/" />;
    }

    return (
        <div className="justify-content-center">
            <div className="px-4 px-lg-5 mb-4">
                <p className="text-reset ff-special fw-normal h1 mb-3">{t('Password Recovery')}</p>
                <p className="font-weight-medium mb-4 d-inline-block">{t('Enter your Email and we will send you a code!')}</p>

                <Form onSubmit={formik.handleSubmit}>

                    {formik.submitCount > 0 && error && !formik.isSubmitting && (
                        <Alert color="danger">
                            <div>{(error.detail && Array.isArray(error.detail) ? error.detail[0].msg : error.detail) || error}</div>
                        </Alert>
                    )}

                    <FormGroup className="mb-3">
                        <Label className="form-label">{t('Email')}</Label>
                        <InputGroup className="mb-3 bg-soft-light rounded-3">
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control form-control-lg border-light bg-soft-light"
                                placeholder="Enter Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                invalid={formik.touched.email && formik.errors.email ? true : false}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                            ) : null}
                        </InputGroup>
                    </FormGroup>

                    <div className="d-grid">
                        <Button color="secondary" size="lg" block className="waves-effect waves-light" type="submit" disabled={formik.isSubmitting || success}>{t('Send Code')}</Button>
                    </div>

                    <div className="mt-2 text-center">
                        <p>{t('Remember It')}? <Link to="login" onClick={(e) => { navigate({ e, to: 'login' }) }} className="font-weight-medium"> {t('Signin')} </Link> </p>
                    </div>

                    {formik.isSubmitting && <div className="d-flex justify-content-center mt-4"><div className="loader"></div></div>}
                </Form>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { loading, error } = selectAuth(state);

    return { loading, error };
};

export default connect(mapStateToProps, { forgetPassword, apiError })(SendCodeForm);